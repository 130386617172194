import React from "react";
import Layout from "../components/layout";
import CoverImg from "../images/imprint.jpg"
import SEO from "../components/seo";

const Impressum = () => {

    return (
        <Layout>
            <SEO
                title="Impressum"
            />

            <div className="relative bg-white">
                <div className="lg:absolute lg:inset-0">
                    <div className="lg:absolute lg:inset-y-0 lg:left-0 lg:w-1/2">
                        <img className="h-56 w-full object-cover lg:absolute lg:h-full"
                             src={CoverImg}
                             alt=""/>
                    </div>
                </div>
                <div
                    className="relative pt-12 pb-16 px-4 sm:pt-16 sm:px-6 lg:px-8 lg:max-w-7xl lg:mx-auto lg:grid lg:grid-cols-2">
                    <div className="lg:col-start-2 lg:pl-8">
                        <div className="text-base max-w-prose mx-auto lg:max-w-lg lg:ml-auto lg:mr-0">
                            <p className="leading-6 text-weag-coral font-semibold tracking-wide uppercase">Impressum</p>
                            <h1 className="mt-2 mb-8 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">Phone Records</h1>
                            <div className="prose text-gray-500">
                                <p>Diese Website wird mit großer Sorgfalt ständig überarbeitet und aktualisiert. Sofern Sie dennoch Fehler bemerken oder Anregungen, Kommentare oder Rückfragen zu dieser Website haben, wenden Sie sich bitte, ggf. unter konkreter Bezugnahme auf einzelne Seiten oder Bereiche, an: <a href="mailto:jpk@phone-records.de">jpk@phone-records.de</a>
                                </p>

                                <h3>Informationen gemäß Telemediengesetz</h3>
                                <p>
                                    Jean-Pierre Knecht<br/>
                                    Triftstraße 50<br/>
                                    13353 Berlin<br/>
                                    <br/>
                                    E: <a href="mailto:jpk@phone-records.de">jpk@phone-records.de</a><br/>
                                    <br/>
                                </p>
                                <h3>Urheberrecht</h3>
                                <p>
                                    Copyright © 2013 - {new Date().getFullYear()} Jean-Pierre Knecht. Alle Rechte sind vorbehalten.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default Impressum;
